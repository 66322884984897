
<template>
  <div>
    <img src="@/assets/logo.svg" class="logo" />
  </div>
</template>

<style scoped>
.logo {
  width: 150px;
  padding: 2px;
}
</style>