// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-14.use[1]!../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-14.use[2]!./base.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

.up-fade {
  opacity: 0;
  animation: up-fade .5s forwards;
  animation-timing-function: ease-in;
}

@keyframes up-fade {
  0% {
    opacity: 0;
    bottom: -20px;
  }

  100% {
    opacity: 1;
    z-index: 1;
    bottom: 0px;
  }
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
