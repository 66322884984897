// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ppg-logo-holder[data-v-a6e4349c] {
  animation-delay: 1.5s;
}
.ppg-logo[data-v-a6e4349c] {
  width: 80px;
  padding: 4px;
}
.distributor-text[data-v-a6e4349c] {
  font-weight: 300;
  font-size: 14px;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
