<script setup lang="ts">
import CommonButton from '@/components/CommonButton.vue';
import Logo from '@/components/Logo.vue';
import PPGLogo from '@/components/PPGLogo.vue';
import router from '@/router';

function goToBussinessCard(id: string) {
  router.push({ name: 'business-card', query: { id } });
}

</script>

<template>
  <main class="home-view">
    <div></div>

    <div>

      <Logo class="up-fade"></Logo>
      
      <div style="height: 16px;"></div>
      
      <div class="under-construction up-fade">
        <h1>Site em desenvolvimento</h1>
        <div style="height: 2px;"></div>
        <p class="come-back-later">Por favor, volte mais tarde.</p>
      </div>
      
      <div style="height: 64px;"></div>
      
      <div class="contact up-fade">
        <p class="get-in-touch">Mas enquanto isso você pode entrar em contato conosco:</p>
        <div style="height: 12px;"></div>
        <div class="d-flex gap-2 justify-content-center">
          <CommonButton @click="goToBussinessCard('CRUZ')" type="secondary" text="CRUZ LIMA"></CommonButton>
          <CommonButton @click="goToBussinessCard('HORACIO')" type="secondary" text="HORÁCIO"></CommonButton>
        </div>
      </div>
      
    </div>

    <PPGLogo class="up-fade"></PPGLogo>
    
  </main>
</template>

<style scoped>

h1 {
  font-size: 24px;
  font-weight: 700;
}

.home-view {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100svh;
  padding: 8px;
  text-align: center;
}

.under-construction {
  animation-delay: .5s;
}

.contact {
  animation-delay: 1s;
}

.come-back-later {
  font-style: italic;
  font-weight: 300;
}

.get-in-touch {
  font-weight: 700;
  font-size: 14px;
}

</style>
