<script setup lang="ts">

defineProps<{
  text: string,
  type?: 'primary' | 'secondary',
}>();

defineEmits<{
  (e: 'click'): void;
}>();

</script>

<template>
  <button :class="`common-button ${type ?? 'primary'}`" @click="$emit('click')">
    {{ text }}
  </button>
</template>

<style scoped>

.common-button {
  background-color: #1894D1;
  border: none;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
}

.common-button:hover {
  background-color: #5CBDEB;
}

.common-button.secondary {
  color: #444;
  background-color: #1894D122;
}

.common-button.secondary:hover {
  background-color: #5CBDEB22;
}

</style>