// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.common-button[data-v-1ef0aead] {
  background-color: #1894D1;
  border: none;
  color: #fff;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  font-weight: bold;
}
.common-button[data-v-1ef0aead]:hover {
  background-color: #5CBDEB;
}
.common-button.secondary[data-v-1ef0aead] {
  color: #444;
  background-color: #1894D122;
}
.common-button.secondary[data-v-1ef0aead]:hover {
  background-color: #5CBDEB22;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
