<template>
  <div class="d-flex align-items-center flex-column ppg-logo-holder">
    <img src="@/assets/ppg_logo.svg" class="ppg-logo" />
    <p class="distributor-text">Distributor of PPG products</p>
  </div>
</template>

<style scoped>

.ppg-logo-holder {
  animation-delay: 1.5s;
}

.ppg-logo {
  width: 80px;
  padding: 4px;
}

.distributor-text {
  font-weight: 300;
  font-size: 14px;
}

</style>