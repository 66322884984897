import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

export class Icons {
  static readonly imported = {
    faEnvelope,
    faPhone,
  };

  public static import() {
    library.add(this.imported);
  }
}
