import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { ComponentOptionsMixin, DefineComponent, ExtractPropTypes, PublicProps } from 'vue'

export interface IRoute {
  path: string
  name: string
  component: DefineComponent<{}, {}, {}, {}, {}, ComponentOptionsMixin, ComponentOptionsMixin, {}, string, PublicProps, Readonly<ExtractPropTypes<{}>>, {}, {}>,
}

export const routes: { [key: string]: RouteRecordRaw } = {
  home: {
    path: '/',
    name: 'home',
    component: HomeView
  },
  businessCard: {
    path: '/business-card',
    name: 'business-card',
    component: () => import('../views/BusinessCardView.vue')
  }
};

const router = createRouter({
  history: createWebHashHistory(),
  routes: Object.values(routes),
})

export default router
