// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
h1[data-v-9ea40744] {
  font-size: 24px;
  font-weight: 700;
}
.home-view[data-v-9ea40744] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100svh;
  padding: 8px;
  text-align: center;
}
.under-construction[data-v-9ea40744] {
  animation-delay: .5s;
}
.contact[data-v-9ea40744] {
  animation-delay: 1s;
}
.come-back-later[data-v-9ea40744] {
  font-style: italic;
  font-weight: 300;
}
.get-in-touch[data-v-9ea40744] {
  font-weight: 700;
  font-size: 14px;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
